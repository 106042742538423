import { Store } from "vuex";
import { getModule, config } from "vuex-module-decorators";
import publicInformation from "~/store/publicInformation";
import jobPost from "~/store/jobPost";
import upskilling from "~/store/upskilling";
import workeraTestAuth from "~/store/workeraTestAuth";
import workeraAuth from "~/store/workeraAuth";
import multiFactorAuthentication from "~/store/multiFactorAuthentication";
import integration from "~/store/integration";

// Set rawError to true by default on all @Action decorators, in this way we can avoid ERR_ACTION_ACCESS_UNDEFINED
// to identify which are the errors in @Action functions
config.rawError = true;

let publicInformationStore: publicInformation;
let jobPostStore: jobPost;
let workeraTestAuthStore: workeraTestAuth;
let upskillingStore: upskilling;
let workeraAuthStore: workeraAuth;
let multiFactorAuthenticationStore: multiFactorAuthentication;
let integrationStore: integration;


function initialiseStores(store: Store<any>): void {
  publicInformationStore = getModule(publicInformation, store);
  jobPostStore = getModule(jobPost, store);
  workeraTestAuthStore = getModule(workeraTestAuth, store);
  upskillingStore = getModule(upskilling, store);
  workeraAuthStore = getModule(workeraAuth, store);
  multiFactorAuthenticationStore = getModule(multiFactorAuthentication, store);
  integrationStore = getModule(integration, store);

}

export {
  initialiseStores,
  publicInformationStore,
  jobPostStore,
  workeraTestAuthStore,
  upskillingStore,
  workeraAuthStore,
  multiFactorAuthenticationStore,
  integrationStore
};
