import { Module, VuexModule, Action, Mutation } from "vuex-module-decorators";

import { WorkeraTestAuthService } from "~/workera/workeraTestAuth/WorkeraTestAuthService";
import { TestToken } from "~/workera/workeraTestAuth/dto/TestToken";
import TokenHandler from "~/workera/utils/TokenHandler";

const workeraTestAuthService: WorkeraTestAuthService = new WorkeraTestAuthService();

@Module({
  name: "workeraTestAuth",
  namespaced: true,
  stateFactory: true
})
export default class workeraTestAuth extends VuexModule {
  private _errorMessage: string = "";

  @Action
  async authenticate(testToken: TestToken) {
    return workeraTestAuthService.authenticate(testToken).then(
      requestTokens => {
        localStorage.setItem("username", testToken.token);
        TokenHandler.saveRefreshAndAcessTokens(
          requestTokens.refresh,
          requestTokens.token
        );
        this.setErrorMessage("");
        const eventData = {
          username: localStorage.getItem("username"),
          email: localStorage.getItem("email")
        };
        this.store.$analytics.identify(
          localStorage.getItem("email"),
          eventData
        );
        this.store.$analytics.track("Signed in", eventData);
      },
      error => {
        console.log(error);
        this.setErrorMessage(error);
        throw error;
      }
    );
  }

  @Action
  async clearSession() {
    localStorage.removeItem("username");
    localStorage.removeItem("token-candidate");
  }

  get errorMessage(): string {
    return this._errorMessage;
  }

  @Mutation
  setErrorMessage(errorMessage: string) {
    this._errorMessage = errorMessage;
  }
}
