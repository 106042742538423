import { Module, VuexModule, Action, Mutation } from "vuex-module-decorators";

import { JobApplicationStates } from "~/workera/jobPost/JobApplicationStates";
import { JobPost } from "~/workera/jobPost/dto/JobPost";
import { JobPostService } from "~/workera/jobPost/JobPostServices";
import { workeraTestAuthStore } from "~/store";
import { NewApplicant } from "~/workera/jobPost/dto/NewApplicant";
import { ReturningApplicant } from "~/workera/jobPost/dto/ReturningApplicant";
import { TestToken } from "~/workera/workeraTestAuth/dto/TestToken";

const jobPostService: JobPostService = new JobPostService();

@Module({
  name: "jobPost",
  namespaced: true,
  stateFactory: true
})
export default class jobPost extends VuexModule {
  private _jobApplicationState: JobApplicationStates =
    JobApplicationStates.LOADING_JOB_POST;
  private _jobPost: JobPost = {
    identifier: "loading ...",
    positionName: "loading...",
    partnerName: "loading ...",
    defaultDaysToCompleteTest: 8,
    cssClass: "",
    publicGuideUrl: ""
  };
  private _errorMessage: string = "";

  @Action
  async applyToJob(applicant: NewApplicant | ReturningApplicant) {
    this.setJobApplicationState(JobApplicationStates.APPLYING);
    try {
      const testToken: TestToken = await jobPostService.applyToJob(
        this.jobPost.identifier,
        applicant
      );
      this.setJobApplicationState(JobApplicationStates.APPLICATION_SUCCEEDED);
      this.setErrorMessage("");
      await workeraTestAuthStore.authenticate(testToken);
    } catch (e) {
      console.log(e);
      this.setJobApplicationState(JobApplicationStates.APPLICATION_FAILED);
      this.setErrorMessage(e);
      throw e;
    }
  }

  @Action
  async fetchJobPost(positionSlug: string) {
    workeraTestAuthStore.clearSession();
    this.setJobApplicationState(JobApplicationStates.LOADING_JOB_POST);
    return jobPostService
      .fetchJobPost(positionSlug)
      .then(res => {
        this.setJobPost(res);
        this.setErrorMessage("");
        this.setJobApplicationState(JobApplicationStates.JOB_POST_LOADED);
      })
      .catch(error => {
        const code = parseInt(error.response && error.response.status);
        if (code === 404) {
          this.store.$router.push("/");
        }
        this.setErrorMessage(error);
        this.setJobApplicationState(JobApplicationStates.JOB_POST_FAILED);
      });
  }

  get applicationState(): JobApplicationStates {
    return this._jobApplicationState;
  }

  get jobPost(): JobPost {
    return this._jobPost;
  }

  get errorMessage(): string {
    return this._errorMessage;
  }

  @Mutation
  setErrorMessage(errorMessage: string) {
    this._errorMessage = errorMessage;
  }

  @Mutation
  setJobApplicationState(state: JobApplicationStates) {
    this._jobApplicationState = state;
  }

  @Mutation
  setJobPost(jobPost: JobPost) {
    this._jobPost = jobPost;
  }
}
