import { TypedService } from "~/workera/utils/TypedService";
import { Cohort } from "~/workera/upskilling/dto/Cohort";
import { TestToken } from "~/workera/workeraTestAuth/dto/TestToken";
import { NewEmployee } from "~/workera/upskilling/dto/NewEmployee";
import { ReturningEmployee } from "~/workera/upskilling/dto/ReturningEmployee";

export class CohortService extends TypedService {
  readonly cohortUrl: string = "/workera_upskilling/public/cohort";

  async fetchCohort(cohortSlug: string): Promise<Cohort> {
    return this.get(`${this.cohortUrl}/${cohortSlug}`).then(sc => {
      return sc.data.data;
    });
  }

  async applyToCohort(
    slug: string,
    employee: NewEmployee | ReturningEmployee
  ): Promise<TestToken> {
    return this.unsafePost(`${this.cohortUrl}/${slug}`, employee).then(
      response => {
        return response.data.data;
      }
    );
  }
}
