import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'

const _d8a8f054 = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _4f58e674 = () => interopDefault(import('../pages/announcement/index.vue' /* webpackChunkName: "pages/announcement/index" */))
const _2161b8d6 = () => interopDefault(import('../pages/auth/index.vue' /* webpackChunkName: "pages/auth/index" */))
const _e9af5104 = () => interopDefault(import('../pages/careers/index.vue' /* webpackChunkName: "pages/careers/index" */))
const _a77bfd02 = () => interopDefault(import('../pages/enterprise_terms_and_conditions/index.vue' /* webpackChunkName: "pages/enterprise_terms_and_conditions/index" */))
const _41d65b9f = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _19d22d7f = () => interopDefault(import('../pages/for-individuals/index.vue' /* webpackChunkName: "pages/for-individuals/index" */))
const _2439db8c = () => interopDefault(import('../pages/job-posts/index.vue' /* webpackChunkName: "pages/job-posts/index" */))
const _cb9368a6 = () => interopDefault(import('../pages/login-error/index.vue' /* webpackChunkName: "pages/login-error/index" */))
const _3f13e7f6 = () => interopDefault(import('../pages/privacy_policy/index.vue' /* webpackChunkName: "pages/privacy_policy/index" */))
const _72b9e62d = () => interopDefault(import('../pages/security/index.vue' /* webpackChunkName: "pages/security/index" */))
const _4cd45b6a = () => interopDefault(import('../pages/skillset-group-not-found/index.vue' /* webpackChunkName: "pages/skillset-group-not-found/index" */))
const _17b2283a = () => interopDefault(import('../pages/successes/index.vue' /* webpackChunkName: "pages/successes/index" */))
const _6fc47c76 = () => interopDefault(import('../pages/terms_and_conditions/index.vue' /* webpackChunkName: "pages/terms_and_conditions/index" */))
const _76fab542 = () => interopDefault(import('../pages/upskilling-cohort/index.vue' /* webpackChunkName: "pages/upskilling-cohort/index" */))
const _62d6c8ed = () => interopDefault(import('../pages/workera-iti-announcement/index.vue' /* webpackChunkName: "pages/workera-iti-announcement/index" */))
const _28a7cb00 = () => interopDefault(import('../pages/auth/company.vue' /* webpackChunkName: "pages/auth/company" */))
const _59d1a31d = () => interopDefault(import('../pages/integrations/workera.vue' /* webpackChunkName: "pages/integrations/workera" */))
const _004767a6 = () => interopDefault(import('../pages/product/workera-connect.vue' /* webpackChunkName: "pages/product/workera-connect" */))
const _b03460f4 = () => interopDefault(import('../pages/product/workera-test.vue' /* webpackChunkName: "pages/product/workera-test" */))
const _0bcacea8 = () => interopDefault(import('../pages/redirect/ai_career_pathways.vue' /* webpackChunkName: "pages/redirect/ai_career_pathways" */))
const _0a8374d8 = () => interopDefault(import('../pages/redirect/candidates_test.vue' /* webpackChunkName: "pages/redirect/candidates_test" */))
const _e7967336 = () => interopDefault(import('../pages/redirect/partners_contact.vue' /* webpackChunkName: "pages/redirect/partners_contact" */))
const _07afd231 = () => interopDefault(import('../pages/redirect/signup.vue' /* webpackChunkName: "pages/redirect/signup" */))
const _dea08148 = () => interopDefault(import('../pages/redirect/upskilling_white_paper.vue' /* webpackChunkName: "pages/redirect/upskilling_white_paper" */))
const _39865007 = () => interopDefault(import('../pages/redirect/workera_test.vue' /* webpackChunkName: "pages/redirect/workera_test" */))
const _3459a351 = () => interopDefault(import('../pages/resource_downloads/ai_career_pathways/index.vue' /* webpackChunkName: "pages/resource_downloads/ai_career_pathways/index" */))
const _4e771d2a = () => interopDefault(import('../pages/resource_downloads/precision_upskilling/index.vue' /* webpackChunkName: "pages/resource_downloads/precision_upskilling/index" */))
const _fe023db6 = () => interopDefault(import('../pages/security/disclosure/index.vue' /* webpackChunkName: "pages/security/disclosure/index" */))
const _5ccaf9f4 = () => interopDefault(import('../pages/badge/_section/_proficiency/_key.vue' /* webpackChunkName: "pages/badge/_section/_proficiency/_key" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

if (process.client) {
  window.history.scrollRestoration = 'manual'
}
const scrollBehavior = function (to, from, savedPosition) {
  // if the returned position is falsy or an empty object,
  // will retain current scroll position.
  let position = false

  // if no children detected and scrollToTop is not explicitly disabled
  if (
    to.matched.length < 2 &&
    to.matched.every(r => r.components.default.options.scrollToTop !== false)
  ) {
    // scroll to the top of the page
    position = { x: 0, y: 0 }
  } else if (to.matched.some(r => r.components.default.options.scrollToTop)) {
    // if one of the children has scrollToTop option set to true
    position = { x: 0, y: 0 }
  }

  // savedPosition is only available for popstate navigations (back button)
  if (savedPosition) {
    position = savedPosition
  }

  return new Promise((resolve) => {
    // wait for the out transition to complete (if necessary)
    window.$nuxt.$once('triggerScroll', () => {
      // coords will be used if no selector is provided,
      // or if the selector didn't match any element.
      if (to.hash) {
        let hash = to.hash
        // CSS.escape() is not supported with IE and Edge.
        if (typeof window.CSS !== 'undefined' && typeof window.CSS.escape !== 'undefined') {
          hash = '#' + window.CSS.escape(hash.substr(1))
        }
        try {
          if (document.querySelector(hash)) {
            // scroll to anchor by returning the selector
            position = { selector: hash }
          }
        } catch (e) {
          console.warn('Failed to save scroll position. Please add CSS.escape() polyfill (https://github.com/mathiasbynens/CSS.escape).')
        }
      }
      resolve(position)
    })
  })
}

export function createRouter() {
  return new Router({
    mode: 'history',
    base: '/',
    linkActiveClass: 'nuxt-link-active',
    linkExactActiveClass: 'nuxt-link-exact-active',
    scrollBehavior,

    routes: [{
      path: "/about",
      component: _d8a8f054,
      name: "about"
    }, {
      path: "/announcement",
      component: _4f58e674,
      name: "announcement"
    }, {
      path: "/auth",
      component: _2161b8d6,
      name: "auth"
    }, {
      path: "/careers",
      component: _e9af5104,
      name: "careers"
    }, {
      path: "/enterprise_terms_and_conditions",
      component: _a77bfd02,
      name: "enterprise_terms_and_conditions"
    }, {
      path: "/faq",
      component: _41d65b9f,
      name: "faq"
    }, {
      path: "/for-individuals",
      component: _19d22d7f,
      name: "for-individuals"
    }, {
      path: "/job-posts",
      component: _2439db8c,
      name: "job-posts"
    }, {
      path: "/login-error",
      component: _cb9368a6,
      name: "login-error"
    }, {
      path: "/privacy_policy",
      component: _3f13e7f6,
      name: "privacy_policy"
    }, {
      path: "/security",
      component: _72b9e62d,
      name: "security"
    }, {
      path: "/skillset-group-not-found",
      component: _4cd45b6a,
      name: "skillset-group-not-found"
    }, {
      path: "/successes",
      component: _17b2283a,
      name: "successes"
    }, {
      path: "/terms_and_conditions",
      component: _6fc47c76,
      name: "terms_and_conditions"
    }, {
      path: "/upskilling-cohort",
      component: _76fab542,
      name: "upskilling-cohort"
    }, {
      path: "/workera-iti-announcement",
      component: _62d6c8ed,
      name: "workera-iti-announcement"
    }, {
      path: "/auth/company",
      component: _28a7cb00,
      name: "auth-company"
    }, {
      path: "/integrations/workera",
      component: _59d1a31d,
      name: "integrations-workera"
    }, {
      path: "/product/workera-connect",
      component: _004767a6,
      name: "product-workera-connect"
    }, {
      path: "/product/workera-test",
      component: _b03460f4,
      name: "product-workera-test"
    }, {
      path: "/redirect/ai_career_pathways",
      component: _0bcacea8,
      name: "redirect-ai_career_pathways"
    }, {
      path: "/redirect/candidates_test",
      component: _0a8374d8,
      name: "redirect-candidates_test"
    }, {
      path: "/redirect/partners_contact",
      component: _e7967336,
      name: "redirect-partners_contact"
    }, {
      path: "/redirect/signup",
      component: _07afd231,
      name: "redirect-signup"
    }, {
      path: "/redirect/upskilling_white_paper",
      component: _dea08148,
      name: "redirect-upskilling_white_paper"
    }, {
      path: "/redirect/workera_test",
      component: _39865007,
      name: "redirect-workera_test"
    }, {
      path: "/resource_downloads/ai_career_pathways",
      component: _3459a351,
      name: "resource_downloads-ai_career_pathways"
    }, {
      path: "/resource_downloads/precision_upskilling",
      component: _4e771d2a,
      name: "resource_downloads-precision_upskilling"
    }, {
      path: "/security/disclosure",
      component: _fe023db6,
      name: "security-disclosure"
    }, {
      path: "/badge/:section?/:proficiency?/:key?",
      component: _5ccaf9f4,
      name: "badge-section-proficiency-key"
    }, {
      path: "/",
      component: _2dfb1658,
      name: "index"
    }],

    fallback: false
  })
}
