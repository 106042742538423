import { Module, VuexModule, Action, Mutation } from "vuex-module-decorators";

import { CohortStates } from "~/workera/upskilling/CohortStates";
import { Cohort } from "~/workera/upskilling/dto/Cohort";
import { CohortService } from "~/workera/upskilling/CohortServices";
import { workeraAuthStore } from "~/store";
import { NewEmployee } from "~/workera/upskilling/dto/NewEmployee";
import { ReturningEmployee } from "~/workera/upskilling/dto/ReturningEmployee";
import { TestToken } from "~/workera/workeraTestAuth/dto/TestToken";
import { CompanyStates } from "~/workera/upskilling/CompanyStates";
import { Company } from "~/workera/upskilling/dto/Company";
import { CompanyService } from "~/workera/upskilling/CompanyServices";


const cohortService: CohortService = new CohortService();
const companyService: CompanyService = new CompanyService();

@Module({
  name: "upskilling",
  namespaced: true,
  stateFactory: true
})
export default class upskilling extends VuexModule {
  private _cohortState: CohortStates = CohortStates.LOADING_COHORT;
  private _cohort: Cohort = {
    slug: "loading ...",
    name: "loading...",
    companyName: "loading ...",
    deadline: "1978-09-15"
  };
  private _company: Company = { slug: "loading ...", name: "loading ...", companyLogo: "string", isSSOEnabled: false, ssoLoginUrl: "" };
  private _errorMessage: string = "";
  private _errorApplyToCompany: string = "";
  private _companyState: CompanyStates = CompanyStates.LOADING_COMPANY;


  @Action
  async applyToCohort(employee: NewEmployee): Promise<void> {
    this.setCohortState(CohortStates.APPLYING);
    try {
      const testToken: TestToken = await cohortService.applyToCohort(
        this.cohort.slug,
        employee
      );
      this.setCohortState(CohortStates.APPLICATION_SUCCEEDED);
      this.setErrorApplyToCohort("");
      return workeraAuthStore.authenticate(testToken);
    } catch (error) {
      this.setCohortState(CohortStates.APPLICATION_FAILED);
      this.setErrorApplyToCohort(error);
      throw error;
    }
  }

  @Action
  async applyToCompany(employee: NewEmployee): Promise<void> {
    this.setCohortState(CohortStates.APPLYING);
    try {
      const response = await companyService.applyToCompany(this.company.slug, employee);
      const testToken: TestToken = response

      this.setCohortState(CohortStates.APPLICATION_SUCCEEDED);
      this.setErrorApplyToCompany("");
      this.setErrorMessage("")

      return workeraAuthStore.authenticate(testToken).then(requestToken => {
        if (response.isManager && !response.isEmployee) {
          this.store.$router.push({ path: "/redirect/candidates_test/" });
          return
        }
        this.store.$router.push({
          path: "/redirect/candidates_test/", query: {
            access: `${requestToken.token}`,
            isManager: response.isManager,
            isEmployee: response.isEmployee
          }
        });

        //window.location = `http://proxy.localhost/candidates/my-services/redirect?access=${requestToken.token}`
      });
    } catch (error) {
      this.setCompanyState(CompanyStates.APPLICATION_FAILED);
      this.setErrorApplyToCompany(error.response.data.message || "Your company email is not registered.");
      throw error;
    }
  }

  @Action
  async loginEmployee(employee: ReturningEmployee): Promise<void> {
    this.setCompanyState(CompanyStates.APPLYING);
    this.setErrorMessage("")
    this.setErrorApplyToCompany("");

    try {
      const response = await companyService.applyToCompany(this.company.slug, employee);
      const testToken: TestToken = response
      this.setCompanyState(CompanyStates.APPLICATION_SUCCEEDED);
      return workeraAuthStore.authenticate(testToken).then(requestToken => {
        if (response.isManager && !response.isEmployee) {
          this.store.$router.push({ path: "/redirect/candidates_test/" });
          return
        }
        this.store.$router.push({
          path: "/redirect/candidates_test/", query: {
            access: `${requestToken.token}`,
            isManager: response.isManager,
            isEmployee: response.isEmployee
          }
        });
      });
    } catch (error) {
      this.setCompanyState(CompanyStates.APPLICATION_FAILED);
      this.setErrorMessage(error.response.data.message || "Error while authenticating")
      this.store.$router.push(`/auth/company/?company=${this._company.slug}`);
      throw error;
    }
  }

  @Action
  async fetchCohort(cohortSlug: string) {
    workeraAuthStore.clearSession();
    this.setCohortState(CohortStates.LOADING_COHORT);
    return cohortService
      .fetchCohort(cohortSlug)
      .then(res => {
        this.setCohort(res);
        this.setCohortState(CohortStates.COHORT_LOADED);
      })
      .catch(error => {
        const code = parseInt(error.response && error.response.status);
        if (code === 404) {
          this.store.$router.push("/");
        }
        this.setErrorMessage(error);
        this.setCohortState(CohortStates.COHORT_FAILED);
      });
  }

  @Action
  async fetchCompany(companySlug: string) {
    workeraAuthStore.clearSession();
    this.setCompanyState(CompanyStates.LOADING_COMPANY);
    return companyService.fetchCompany(companySlug)
      .then(
        res => {
          this.setCompany(res);
          this.setCompanyState(CompanyStates.COMPANY_LOADED);
        }
      ).catch(
        error => {
          const code = parseInt(error.response && error.response.status);
          if (code === 404) {
            this.store.$router.push("/")
          }
          this.setErrorMessage(error);
          this.setCompanyState(CompanyStates.COMPANY_FAILED);
        }
      );
  }

  get cohortState(): CohortStates {
    return this._cohortState;
  }

  get cohort(): Cohort {
    return this._cohort;
  }

  get errorMessage(): string {
    return this._errorMessage;
  }

  get errorApplyToCohort(): string {
    return this._errorApplyToCohort;
  }

  get company(): Company {
    return this._company;
  }

  get companyStates(): CompanyStates {
    return this._companyState;
  }

  get errorApplyToCompany(): string {
    return this._errorApplyToCompany;
  }

  @Mutation
  setErrorMessage(errorMessage: string) {
    this._errorMessage = errorMessage;
  }
  @Mutation
  setErrorApplyToCohort(errorApplyToCohort: string) {
    this._errorApplyToCohort = errorApplyToCohort;
  }

  @Mutation
  setCohortState(state: CohortStates) {
    this._cohortState = state;
  }

  @Mutation
  setCohort(cohort: Cohort) {
    this._cohort = cohort;
  }

  @Mutation
  setCompany(company: Company) {
    this._company = company;
  }

  @Mutation
  setCompanyState(state: CompanyStates) {
    this._companyState = state;
  }

  @Mutation
  setErrorApplyToCompany(errorApplyToCompany: string) {
    this._errorApplyToCompany = errorApplyToCompany;
  }
}
