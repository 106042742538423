import { Module, VuexModule, Action, Mutation } from "vuex-module-decorators";
import { MultiFactorStatus } from "~/workera/models/multifactorAuthentication/MultiFactorAuthenticationStates";
import { MultiFactorService } from "~/workera/services/MultiFactorService";

const mfaService: MultiFactorService = new MultiFactorService();

@Module({
  name: "multiFactorAuthentication",
  namespaced: true,
  stateFactory: true
})
export default class multiFactorAuthentication extends VuexModule {
  private _mfaStatus: MultiFactorStatus = MultiFactorStatus.LOADING_ACTION;
  private _phoneNumber: String = "";
  private _email: String = "";
  private _password: String = "";
  private _errorMessage: String = "";

  @Action
  async fetchPublicMFAStatus(username: string) {
    const mfaStatus = await mfaService.fetchPublicMFAStatus(username);
    this.setMultiFactorAuthenticationStatus(mfaStatus.mfaStatus);
  }

  @Action
  async fetchPhoneNumber(username: string) {
    this.setPhoneNumberValue("");
    return mfaService.fetchPublicPhoneNumber(username).then(res => {
      this.setPhoneNumberValue(res);
    });
  }

  @Action
  async sendPublicVerificationCode(username: string) {
    return mfaService.sendPublicVerificationCode(username);
  }

  get mfaStatus(): MultiFactorStatus {
    return this._mfaStatus;
  }

  get phoneNumber(): String {
    return this._phoneNumber;
  }

  get email(): String {
    return this._email;
  }

  get password(): String {
    return this._password;
  }

  get errorMessage(): String {
    return this._errorMessage;
  }

  @Mutation
  setMultiFactorAuthenticationStatus(
    multiFactorAuthenticationStatus: MultiFactorStatus
  ) {
    this._mfaStatus = multiFactorAuthenticationStatus;
  }

  @Mutation
  setPhoneNumberValue(phoneNumber: String) {
    this._phoneNumber = phoneNumber;
  }

  @Mutation
  setEmail(email: string) {
    this._email = email;
  }

  @Mutation
  setPassword(password: string) {
    this._password = password;
  }

  @Mutation
  setErrorMessage(errorMessage: String) {
    this._errorMessage = errorMessage;
  }
}
