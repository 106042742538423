import { Option } from 'space-lift'
import Cookies from 'js-cookie'

export default class TokenHandler {
  static getToken(): string {
    return Option(
      localStorage.getItem(
        `token-candidate-${localStorage.getItem('username')}`
      )
    ).fold(() => '', token => token)
  }
  static getRefreshToken(): string {
    return Option(
      localStorage.getItem(
        `token-candidate-refresh-${localStorage.getItem('username')}`
      )
    ).fold(() => '', token => token)
  }
  static getHostName(): string {
    const urlParts = new URL(`${process.env.frontendUrl}`).hostname.split('.')

    return urlParts
      .slice(0)
      .slice(-(urlParts.length === 4 ? 3 : 2))
      .join('.')
  }
  static saveRefreshAndAcessTokens(refreshToken: String, accessToken: String) {
    this.saveAccessToken(accessToken)
    localStorage.setItem(
      `token-candidate-refresh-${localStorage.getItem('username')}`,
      `${refreshToken}`
    )
  }
  static saveAccessToken(accessToken: String) {
    localStorage.setItem(
      `token-candidate-${localStorage.getItem('username')}`,
      `${accessToken}`
    )
    Cookies.set(`user_token`, `${accessToken}`, {
      domain: `.${this.getHostName()}`,
    })
  }
  static removeRefreshAndAccessTokens() {
    localStorage.removeItem(
      `token-candidate-${localStorage.getItem('username')}`
    )
    localStorage.removeItem(
      `token-candidate-refresh-${localStorage.getItem('username')}`
    )
    Cookies.remove(`user_token`, { domain: `.${this.getHostName()}` })
  }
}
