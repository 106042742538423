import { JsonConvert } from "json2typescript";
import { TypedService } from "./TypedService";
import { MFAStatus } from "~/workera/models/multifactorAuthentication/MultiFactorAuthenticationStates";

export class MultiFactorService extends TypedService {
  readonly mfaStatusUrl: string = "/workera-auth/mfa-status";

  readonly publicPhoneNumberUrl: string =
    "/workera-auth/public/phone-number/{}";

  readonly publicVerificationCodeUrl: string =
    "/workera-auth/public/verification-code/{}";

  readonly publicMFAStatusUrl: string = "/workera-auth/public/mfa-status/{}";

  private jsonConvert: JsonConvert = new JsonConvert();

  async fetchPublicMFAStatus(username: string): Promise<MFAStatus> {
    return this.get(this.publicMFAStatusUrl.replace("{}", username)).then(
      response => {
        return this.jsonConvert.deserializeObject(response.data, MFAStatus);
      }
    );
  }

  async fetchPublicPhoneNumber(username: string): Promise<String> {
    return this.get(this.publicPhoneNumberUrl.replace("{}", username)).then(
      res => {
        return res.data as String;
      }
    );
  }

  async sendPublicVerificationCode(username: string) {
    return this.get(this.publicVerificationCodeUrl.replace("{}", username));
  }

  async setMFAStatus(mfaStatus: MFAStatus) {
    return this.post(
      this.mfaStatusUrl,
      this.jsonConvert.serializeObject(mfaStatus)
    );
  }
}
