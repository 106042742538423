import { Module, VuexModule, Action, Mutation } from "vuex-module-decorators";
import { PublicService } from "~/workera/public/PublicService";

const publicService: PublicService = new PublicService();

@Module({
  name: "publicInformation",
  namespaced: true,
  stateFactory: true
})
export default class publicInformation extends VuexModule {
  private _skillsEvaluatedCount: Number = 130000;

  @Action
  async fetchEvaluatedSkillsCount() {
    return publicService
      .fetchSkillsEvaluatedCount()
      .then(res => this.setSkillsEvaluatedCount(res.skillsCount));
  }

  get skillsEvaluatedCount() {
    return this._skillsEvaluatedCount;
  }

  @Mutation
  setSkillsEvaluatedCount(setSkillsEvaluatedCount: Number) {
    this._skillsEvaluatedCount = setSkillsEvaluatedCount;
  }
}
