import { TypedService } from "../utils/TypedService";
import { SkillsCount } from "~/workera/public/dto/SkillsCount";

export class PublicService extends TypedService {
  readonly skillsCountUrl: string = "/candidate/skills-count";

  fetchSkillsEvaluatedCount(): Promise<SkillsCount> {
    return this.get(`${this.skillsCountUrl}`).then(sc => {
      return { skillsCount: sc.data.skills_count };
    });
  }
}
