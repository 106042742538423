import { TypedService } from "~/workera/utils/TypedService";
import { TestToken } from "~/workera/workeraTestAuth/dto/TestToken";
import { RequestTokens } from "../utils/dto/RequestTokens";

export class WorkeraTestAuthService extends TypedService {
  readonly obtainTokenUrl: string = "/auth/obtain_token";

  authenticate(testToken: TestToken): Promise<RequestTokens> {
    return this.post(this.obtainTokenUrl, {
      username: testToken.token,
      password: testToken.password,
      verification_code: testToken.verificationCode,
      recovery_code: testToken.recoveryCode
    }).then(res => {
      return {
        token: res.data.access,
        refresh: res.data.refresh,
        learning_track: res.data.learning_track,
        companyAdmin: res.data.company_admin,
        userType: res.data.user_type,
        email: res.data.email,
        skillSetId: res.data.skill_set_id,
        onboardingStep: res.data.onboarding_step,
        firstName: res.data.first_name
      };
    });
  }
}
