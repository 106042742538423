import {
  JsonConverter,
  JsonCustomConvert,
  JsonObject,
  JsonProperty
} from "json2typescript";

export enum MultiFactorStatus {
  NO_ACTION_YET = "NO_ACTION_YET",
  MFA_SET = "MFA_SET",
  MFA_SKIPPED = "MFA_SKIPPED",
  LOADING_ACTION = "LOADING_ACTION"
}
@JsonConverter
class MultiFactorStatusConverter
  implements JsonCustomConvert<MultiFactorStatus> {
  serialize(multiFactorStatus: MultiFactorStatus): string {
    switch (multiFactorStatus) {
      case MultiFactorStatus.MFA_SET:
        return "MFA_SET";
      case MultiFactorStatus.MFA_SKIPPED:
        return "MFA_SKIPPED";
      default:
        return "NO_ACTION_YET";
    }
  }
  deserialize(multiFactorStatus: string): MultiFactorStatus {
    switch (multiFactorStatus) {
      case "MFA_SET":
        return MultiFactorStatus.MFA_SET;
      case "MFA_SKIPPED":
        return MultiFactorStatus.MFA_SKIPPED;
      default:
        return MultiFactorStatus.NO_ACTION_YET;
    }
  }
}

@JsonObject("MFAStatus")
export class MFAStatus {
  @JsonProperty("mfa_status", MultiFactorStatusConverter)
  mfaStatus: MultiFactorStatus = MultiFactorStatus.NO_ACTION_YET;
}
