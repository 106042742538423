import { TypedService } from "~/workera/utils/TypedService";
import { Cohort } from "~/workera/upskilling/dto/Cohort";
import { TestToken } from "~/workera/workeraTestAuth/dto/TestToken";
import { NewEmployee } from "~/workera/upskilling/dto/NewEmployee";
import { ReturningEmployee } from "~/workera/upskilling/dto/ReturningEmployee";


export class CompanyService extends TypedService {
    readonly companytUrl: string = "/workera_upskilling/public/company";

    async fetchCompany(companySlug: string): Promise<Cohort> {
        return this.get(`${this.companytUrl}/${companySlug}`).then(sc => {
            return sc.data.data;
        });
    }

    async applyToCompany(slug: string, employee: NewEmployee | ReturningEmployee): Promise<TestToken> {
        return this.unsafePost(`${this.companytUrl}/${slug}`, employee)
            .then(response => {
                return response.data.data;
            })
    }
}
