import Vue from "vue";
import {
  Slide,
  ScaleDown,
  ScaleRotate,
  Reveal,
  Push,
  PushRotate
} from "vue-burger-menu";

Vue.component("Slide", Slide);
Vue.component("ScaleDownMenu", ScaleDown);
Vue.component("ScaleRotateMenu", ScaleRotate);
Vue.component("RevealMenu", Reveal);
Vue.component("PushMenu", Push);
Vue.component("PushRotateMenu", PushRotate);
