import axios, { AxiosInstance } from "axios";
import { Option, None } from "space-lift";
import TokenHandler from "./TokenHandler";

export class TypedService {
  api: AxiosInstance;
  readonly refreshTokenURL: string = "auth/refresh_token";
  constructor(jwt: Option<string> = None) {
    this.api = this.initAxios(jwt);
  }

  addInterceptors(api: AxiosInstance) {
    const refreshTokenInterceptor = api.interceptors.response.use(
      response => response,
      error => {
        if (error.response.status !== 401) {
          return Promise.reject(error);
        }
        api.interceptors.response.eject(refreshTokenInterceptor);
        const refreshToken = TokenHandler.getRefreshToken();
        return api
          .post(this.refreshTokenURL, {
            refresh: refreshToken
          })
          .then(response => {
            if (response.data.access) {
              TokenHandler.saveAccessToken(response.data.access);
              const refreshedToken = TokenHandler.getToken();
              error.response.config.headers[
                "Authorization"
              ] = `JWT ${refreshedToken}`;
              return axios(error.response.config);
            }
            return Promise.reject(error);
          })
          .catch(error => {
            TokenHandler.removeRefreshAndAccessTokens();
            return Promise.reject(error);
          });
      }
    );
    return api;
  }

  initAxios(jwt: Option<string> = None) {
    const api = axios.create({
      baseURL: process.env.baseUrl
    });
    if (process.client) {
      const jsonWebToken = jwt.fold(
        () => {
          return TokenHandler.getToken();
        },
        token => token
      );
      api.defaults.headers.common["Authorization"] = `JWT ${jsonWebToken}`;
    }

    api.defaults.headers.post["Content-Type"] = "application/json";
    api.defaults.headers.post["Accept"] = "application/json";

    return api;
  }

  initSafeAxios() {
    return this.addInterceptors(this.initAxios());
  }

  get(url: string) {
    const api = this.initSafeAxios();
    return api.get(url);
  }

  patch(url: string, payload: Object) {
    const api = this.initSafeAxios();
    return api.patch(url, payload);
  }

  post(url: string, payload: Object) {
    const api = this.initSafeAxios();
    return api.post(url, payload);
  }

  unsafePost(url: string, payload: Object) {
    const api = this.initAxios();
    return api.post(url, payload);
  }

  onError(error: any) {
    const code = parseInt(error.response && error.response.status);
    if (code === 404) {
      alert("Backend service '" + error.config.url + "' not found");
    }
    if (code === 401) {
      TokenHandler.removeRefreshAndAccessTokens();
      location.reload(true);
    }
  }
}
