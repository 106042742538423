import { Module, VuexModule, Action, Mutation } from "vuex-module-decorators";

import { WorkeraTestAuthService } from "~/workera/workeraTestAuth/WorkeraTestAuthService";
import { TestToken } from "~/workera/workeraTestAuth/dto/TestToken";
import TokenHandler from "~/workera/utils/TokenHandler";

const workeraTestAuthService: WorkeraTestAuthService = new WorkeraTestAuthService();

@Module({
  name: "workeraAuth",
  namespaced: true,
  stateFactory: true
})
export default class workeraAuth extends VuexModule {
  private _errorMessage: string = "";

  @Action
  async authenticate(testToken: TestToken) {
    return workeraTestAuthService.authenticate(testToken).then(
      requestTokens => {
        localStorage.setItem("username", testToken.token);
        localStorage.setItem("email", requestTokens.email);
        localStorage.setItem(`skill-set-${testToken.token}`, requestTokens.skillSetId)
        localStorage.setItem(`onboarding-step-${testToken.token}`, String(requestTokens.onboardingStep))
        localStorage.setItem(`user-first-name-${testToken.token}`, requestTokens.firstName)

        TokenHandler.saveRefreshAndAcessTokens(
          requestTokens.refresh,
          requestTokens.token
        );
        localStorage.setItem(
          `user-type-${testToken.token}`,
          requestTokens.userType
        );

        localStorage.setItem(
          `user-admin-${localStorage.getItem("username")}`,
          String(requestTokens.companyAdmin)
        );
        const eventData = {
          username: localStorage.getItem("username"),
          email: localStorage.getItem("email")
        };
        localStorage.setItem(
          `learning-track-${testToken.token}`,
          requestTokens.learning_track.replace(/"/g, "'")
        );
        this.store.$analytics.identify(
          localStorage.getItem("email"),
          eventData
        );
        this.store.$analytics.track("Signed in", eventData);

        this.setErrorMessage("");
        return requestTokens
      },
      error => {
        console.log(error);
        this.setErrorMessage(error);
        throw error;
      }
    );
  }

  @Action
  async clearSession() {
    TokenHandler.removeRefreshAndAccessTokens();
    localStorage.removeItem("username");
  }

  get errorMessage(): string {
    return this._errorMessage;
  }

  @Mutation
  setErrorMessage(errorMessage: string) {
    this._errorMessage = errorMessage;
  }
}
