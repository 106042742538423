//Segment
export default (context, inject) => {
  context.app.router.beforeEach(function(to, from, next) {
    if (window.analytics && !context.isDev) {
      if (!to.path.includes("/redirect/")) {
        // console.log("route", to.path, to.name, to.fullPath);
        window.analytics.page(to.path);
      }
    }
    next();
  });
  inject("analytics", {
    track: (name, properties) => {
      if (window.analytics) {
        try {
          window.analytics.track(name, properties);
        } catch (e) {}
      }
    },
    identify: (user_identifier, traits) => {
      if (window.analytics) {
        try {
          window.analytics.identify(user_identifier, traits);
        } catch (e) {}
      }
    },
    reset: () => {
      if (window.analytics) {
        try {
          window.analytics.reset();
        } catch (e) {}
      }
    }
  });
};

!(function() {
  var segmentKey = process.env.segmentKey;
  var analytics = (window.analytics = window.analytics || []);
  if (!analytics.initialize)
    if (analytics.invoked)
      window.console &&
        console.error &&
        console.error("Segment snippet included twice.");
    else {
      analytics.invoked = !0;
      analytics.methods = [
        "trackSubmit",
        "trackClick",
        "trackLink",
        "trackForm",
        "pageview",
        "identify",
        "reset",
        "group",
        "track",
        "ready",
        "alias",
        "debug",
        "page",
        "once",
        "off",
        "on"
      ];
      analytics.factory = function(t) {
        return function() {
          var e = Array.prototype.slice.call(arguments);
          e.unshift(t);
          analytics.push(e);
          return analytics;
        };
      };
      for (var t = 0; t < analytics.methods.length; t++) {
        var e = analytics.methods[t];
        analytics[e] = analytics.factory(e);
      }
      analytics.load = function(t, e) {
        var n = document.createElement("script");
        n.type = "text/javascript";
        n.async = !0;
        n.src =
          "https://cdn.segment.com/analytics.js/v1/" + t + "/analytics.min.js";
        var a = document.getElementsByTagName("script")[0];
        a.parentNode.insertBefore(n, a);
        analytics._loadOptions = e;
      };
      analytics.SNIPPET_VERSION = "4.1.0";
      // setTimeout(() => {
      //   analytics.load(segmentKey);
      // }, 2000);
      //analytics.page();
    }
})();
