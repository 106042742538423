import { Module, VuexModule, Action, Mutation } from "vuex-module-decorators";
import { IntegrationService } from "~/workera/services/IntegrationService";

const integrationService: IntegrationService = new IntegrationService();

@Module({
  name: "integration",
  namespaced: true,
  stateFactory: true,
})
export default class integration extends VuexModule {
  private _tab: string = ""
  private _loginSubmited: boolean = false



  @Action
  async WorkeraSignIn(ssoLoginUrl: string) {
    return integrationService.WorkeraSignIn(ssoLoginUrl);
  }

  @Action
  async setTabAction(tab: string) {
    this.setTab(tab)
  }

  @Action
  async setLoginSubmitedAction(submited: boolean) {
    this.setLoginSubmited(submited)
  }

  get tab() {
    return this._tab
  }

  @Mutation
  setTab(tab: string) {
    this._tab = tab
  }

  @Mutation
  setLoginSubmited(submited: boolean) {
    this._loginSubmited = submited
  }

}