import { TypedService } from "~/workera/utils/TypedService";
import { JobPost } from "~/workera/jobPost/dto/JobPost";
import { TestToken } from "~/workera/workeraTestAuth/dto/TestToken";
import { NewApplicant } from "~/workera/jobPost/dto/NewApplicant";
import { ReturningApplicant } from "~/workera/jobPost/dto/ReturningApplicant";
import { Err } from "space-lift";

export class JobPostService extends TypedService {
  readonly jobPostUrl: string = "/workera_test/job-post";

  async fetchJobPost(positionSlug: string): Promise<JobPost> {
    return this.get(`${this.jobPostUrl}/${positionSlug}`).then(sc => {
      return sc.data;
    });
  }

  async applyToJob(
    identifier: string,
    applicant: NewApplicant | ReturningApplicant
  ): Promise<TestToken> {
    return this.post(`${this.jobPostUrl}/${identifier}`, applicant)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        const code = parseInt(error.response && error.response.status);
        if (code === 400) {
          throw new Error(error.response.data.message);
        }
        throw error;
      });
  }
}
